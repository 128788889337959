import { Button, IconButton } from "@chakra-ui/button";
import { Box, BoxProps } from "@chakra-ui/layout";
import { Datepicker, START_DATE, END_DATE } from "@datepicker-react/styled";
import { FocusedInput, OnDatesChangeProps } from "@datepicker-react/hooks";
import ReactDOM from "react-dom";
import { useRef, useState } from "react";
import { CalendarIcon } from "@chakra-ui/icons";
import { useTheme } from "@chakra-ui/react";
import { BiFontFamily } from "react-icons/bi";
import moment from "moment";
import { format } from "date-fns";

interface DateRangePickerProps extends BoxProps {
  startDate: Date | null;
  endDate: Date | null;
  onDatesChange: (data: OnDatesChangeProps) => void;
  minBookingDate?: Date;
  maxBookingDate?: Date;
  horizontalOffset?: number; // Shifts the calendar horizontally
  maxSelectedDays?: number;
  showDateRangeOnButton?: boolean;
  disabled?: boolean;
  showClose?: boolean;
}

const getMaxBookingDate = (
  focusedInput: FocusedInput,
  startDate: Date | null,
  maxBookingDateParam?: Date,
  maxSelectedDays?: number
) => {
  // If use is selecting the end date and there is a max number of days to select, set the max booking date so they
  // cannot select past that range
  if (focusedInput === "endDate" && startDate) {
    if (maxSelectedDays !== undefined) {
      const maxDateFromStartDate = moment(startDate).add(maxSelectedDays, "days");

      // If the configured max booking date is beyond the max of this period then use that instead
      if (maxBookingDateParam !== undefined && maxDateFromStartDate.isAfter(maxBookingDateParam)) {
        return maxBookingDateParam;
      }
      return maxDateFromStartDate.toDate();
    }
  }

  return maxBookingDateParam;
};

const hasParentClass = (child: HTMLInputElement, classname: string): boolean => {
  if (child.className.split && child.className.split(" ").indexOf(classname) >= 0) return true;
  try {
    //Throws TypeError if child doesn't have parent any more
    return !!child.parentNode && hasParentClass(child.parentNode as HTMLInputElement, classname);
  } catch (TypeError) {
    return false;
  }
};

export const DateRangePicker = ({
  startDate,
  endDate,
  onDatesChange,
  minBookingDate,
  maxBookingDate,
  horizontalOffset = 0,
  maxSelectedDays,
  showDateRangeOnButton,
  disabled,
  placeholder,
  showClose = false,
  ...other
}: DateRangePickerProps) => {
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [focusedInput, setFocusedInput] = useState<FocusedInput>(START_DATE);
  const theme = useTheme();
  const datePickerRef = useRef<HTMLButtonElement>(null);

  const hideDatePicker = () => {
    setDatePickerVisible(false);
  };

  const showDatePicker = () => {
    if (datePickerVisible) {
      return;
    }

    // Chakra's modal swallows clicks so if one's open whack the click handler on that instead
    const modalContainers = document.getElementsByClassName("chakra-modal__content-container");
    const container = modalContainers && modalContainers.length > 0 ? modalContainers[0] : document;

    const documentClickHandler = (e: MouseEvent) => {
      if (!hasParentClass(e.target as HTMLInputElement, "date-picker-wrapper")) {
        // Ignore clicks on datepicker component
        container.removeEventListener("click", documentClickHandler as EventListenerOrEventListenerObject);
        // Wrap this in a timeout so that if it date button is clicked it doesn't immediately reopen
        setTimeout(() => {
          setDatePickerVisible(false);
        });
      }
    };

    setDatePickerVisible(true);
    // Let current callstack finish then add background click listener
    setTimeout(() =>
      container.addEventListener("click", documentClickHandler as EventListenerOrEventListenerObject)
    );
  };

  const handleDatesChange = (data: OnDatesChangeProps) => {
    if (focusedInput === START_DATE) {
      onDatesChange({ ...data, endDate: null }); // Clear end date if reset date selection, is a bit nicer
    } else {
      onDatesChange(data);
    }
    setFocusedInput(data.focusedInput || START_DATE);
  };

  // Portal for DatePicker
  const datePickerPortal = datePickerVisible
    ? ReactDOM.createPortal(
        <Box
          boxShadow="2xl"
          borderRadius={2}
          className="date-picker-wrapper"
          position="fixed" // Use fixed positioning to place the date picker relative to the viewport
          // Calculate position based on the datePickerRef's current position
          top={`${datePickerRef.current?.getBoundingClientRect().bottom}px`}
          left={`${datePickerRef.current?.getBoundingClientRect().left}px`}
          sx={{
            "*": {
              fontFamily: `${theme.fonts.body} !important`,
              boxShadow: "none !important",
            },
            marginLeft: horizontalOffset,
          }}
          zIndex="var(--chakra-zIndices-popover)"
          backgroundColor="white"
        >
          <Datepicker
            onDatesChange={handleDatesChange}
            onClose={() => setTimeout(() => hideDatePicker())} // Wrapped in a timeout so the button click handler can trigger first and not immediately reopen the modal
            startDate={startDate} // Date or null
            endDate={endDate} // Date or null
            focusedInput={focusedInput} // START_DATE, END_DATE or null
            numberOfMonths={2}
            minBookingDate={minBookingDate}
            maxBookingDate={getMaxBookingDate(focusedInput, startDate, maxBookingDate, maxSelectedDays)}
            showSelectedDates={false}
            showResetDates={false}
            showClose={showClose}
          />
        </Box>,
        document.body // Append to body or another element outside your modal
      )
    : null;

  return (
    <Box borderRadius="md" position="relative" bg="white" color="gray.700" {...other}>
      <Button
        ref={datePickerRef}
        onClick={() => (datePickerVisible ? hideDatePicker() : showDatePicker())}
        background={datePickerVisible ? "black" : "white"}
        color={datePickerVisible ? "white" : "black"}
        _hover={{ color: datePickerVisible ? "white" : "black" }}
        leftIcon={<CalendarIcon />}
        variant="outline"
        disabled={disabled}
        width="100%"
      >
        {showDateRangeOnButton && startDate && endDate
          ? `${format(startDate, "dd/M/yyyy")}-${format(endDate, "dd/M/yyyy")}`
          : placeholder || "Date range"}
      </Button>
      {datePickerPortal}
    </Box>
  );
};
